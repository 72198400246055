import React from "react";
import {SECTIONS_DICT} from "../constants";
import {Card, CardContent, CardHeader, Grid} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        card: {
            backgroundColor: "#262525",
            color: "#7b7b7b",
            textAlign: "center",
            minHeight: "225px",
            padding: '50px',
        },
    }));
export default function Education() {
    const classes = useStyles();
    const moduleInfo = SECTIONS_DICT['education'];
    return (
        <div style={{flexGrow: 5, paddingTop: '40px'}} id={moduleInfo.id}>
            <Grid container justify={"center"} alignContent={"center"} alignItems={"center"}>
                <Grid item xs={10} md={8} lg={6}>
                    <Card variant={"elevation"} className={classes.card} elevation={5}>
                        <CardHeader
                            title={'Education'}
                        />
                        <CardContent style={{minHeight: "150px"}}>
                            <h4>B.Eng. Computer Systems Engineering</h4>
                            <p>
                                <span style={{fontSize: 'small'}}>Instituto Tecnologico de Tijuana</span><br/>
                                <span style={{fontSize: 'smaller', fontStyle: 'italic'}}>2009 - 2014</span>
                            </p>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}