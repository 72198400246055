import React from "react";
import {SECTIONS_DICT, TECH_STACK_LIST} from "../constants";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {Card, CardContent, CardHeader, Grid} from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
            width: '100%',
        },
        card: {
            backgroundColor: "#262525",
            color: "#7b7b7b",
            textAlign: "center",
            minHeight: "225px",
        },
        accordion: {
            width: '100%',
            color: "#7b7b7b",
        },
        summary: {
            placeContent: 'center',
            backgroundColor: '#2e3b55'
        },
        details: {
            placeContent: 'center',
            backgroundColor: '#495867',
            color: '#2d2d2d'
        }
    }));

export default function TechStack() {
    const classes = useStyles();
    const moduleInfo = SECTIONS_DICT['techstack'];
    return (
        <div style={{flexGrow: 5, paddingTop: '40px'}} id={moduleInfo.id}>
            <Grid container justify={"center"} alignContent={"center"} alignItems={"center"}>
                <Grid item xs={10} md={8} lg={6}>
                    <Card variant={"elevation"} className={classes.card} elevation={5}>
                        <CardHeader
                            title={'Tech Stack'}
                        />
                        <CardContent style={{minHeight: "150px"}}>
                            <Card className={classes.card} elevation={0}>
                                <CardContent>
                                    <Grid container>
                                        {TECH_STACK_LIST.map((tech) =>
                                            <Accordion className={classes.accordion}>
                                                <AccordionSummary
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                    className={classes.summary}
                                                >{tech.concept}
                                                </AccordionSummary>
                                                <AccordionDetails className={classes.details}>
                                                    <Typography>
                                                        {tech.details.map(detail =>
                                                            <Grid item xs={12}
                                                                  style={{fontStyle: 'italic'}}>{detail}</Grid>
                                                        )}
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        )}
                                    </Grid>
                                </CardContent>
                            </Card>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}