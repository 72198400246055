import React from "react";

export default function Layout({children}: {
    children: React.ReactNode
}) {
    return (
        <main style={{backgroundColor: "#262525", color: "#7b7b7b", fontSize: "15px"}}>

            <title>Carlos G. Durazo - Software Engineer</title>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"/>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway:wght@200&display=swap"/>

            {children}
        </main>
    );
}
