import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Grid} from "@material-ui/core";
import AlternateEmailOutlinedIcon from '@material-ui/icons/AlternateEmailOutlined';
import PhoneIphoneOutlinedIcon from '@material-ui/icons/PhoneIphoneOutlined';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
// @ts-ignore
import { SocialIcon } from 'react-social-icons';
import {CONTACT_LIST, iSection, SOCIAL_MEDIA_LIST} from "./constants";

const useStyles = makeStyles(() => ({
    headerBackgroundImage: {
        backgroundImage: 'url(/imgs/background.jpg), url(/imgs/background.webp)',
        minHeight: "500px",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        paddingTop: "50px",
        color: '#7b7b7b',
        marginBottom: '20px',
    },
    infoCard: {
        padding: "40px",
        borderRadius: "0 0 2px 2px",
        backgroundColor: "#262525",
        height: "100%"
    },
    infoHeadings: {
        display: "inline-block",
        width: "100%",
        fontWeight: "bolder",
        color: "#7b7b7b",
        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.29)",
        fontFamily: "'Raleway', sans-serif"
    },
    infoNameHeading: {
        textTransform: "uppercase"
    },
    infoTitleHeading: {
        textTransform: "capitalize"
    },
    infoContact: {
        color: "#7b7b7b",
        maxWidth: '300px',
        display: "inherit",
    },
    gridContainer: {
        justifyContent: 'center',
        textAlign: 'center',
    },
    profilePic: {
        height: "400px",
    },
    label: {
        display: "inline-block"
    },
    labelIcon: {
        display: "inline-block",
        float: "left",
        height: "26px",
    },
    labelContent: {
        fontSize: "15px",
        fontWeight: "lighter",
        lineHeight: "20px",
        float: "left",
        paddingLeft: "10px",
    },

}));
export default function HeadComponent() {
    const classes = useStyles();
    const iconSwitch: any = (type: string) => {
        switch (type) {
            case 'email':
                return <AlternateEmailOutlinedIcon/>;
            case 'website':
                return <LanguageOutlinedIcon/>;
            case 'phone':
                return <PhoneIphoneOutlinedIcon/>;
            default:
                return;
        }
    }
    const profileGridElement = (moveToRight: boolean) => {
        return (
            <Grid item sm={6} md={4} lg={6} className={classes.gridContainer}>
                <picture className={classes.profilePic} style={moveToRight ? {float: 'right'} : {}}>
                    <source srcSet={'/imgs/profile.webp'} type="image/webp" className={classes.profilePic}/>
                    <source srcSet={'/imgs/profile.jpeg'} type="image/jpeg" className={classes.profilePic}/>
                    <img src={'/imgs/profile.webp'} alt={'me'} className={classes.profilePic}/>
                </picture>

            </Grid>
        );
    }
    const infoCardGridElement = (
        <Grid item sm={6} md={4} lg={6} className={classes.infoCard}>
            <span className={classes.infoHeadings}>
                <h1 className={classes.infoNameHeading}>Carlos Guillermo Durazo</h1>
                <h3 className={classes.infoTitleHeading}>Computer Systems/Software Engineer</h3>
            </span>
            <span className={classes.infoHeadings}>
                <div className={classes.infoContact}>
                    {CONTACT_LIST.map((section: iSection) =>
                        <span className={classes.label}>
                            <span className={classes.labelIcon}>{iconSwitch(section.iconType)}</span>
                            <span className={classes.labelContent}>{section.title}</span>
                        </span>
                    )}
                    <span className={classes.label}>
                        {SOCIAL_MEDIA_LIST.map((social) =>
                            <span style={{padding: '0 5px'}}>
                                <SocialIcon url={social.url} network={social.network} bgColor={'#495867'}/>
                            </span>
                        )}
                    </span>
                </div>
            </span>
        </Grid>
    );

    return (
        <div style={{display: "inline-block", width: '100%'}}>
            <div className={classes.headerBackgroundImage}>
                <Box display={{xs: 'block', sm: 'none', md: 'none', lg: 'none'}}>
                    <Grid container className={classes.gridContainer}>
                        {profileGridElement(false)}
                        {infoCardGridElement}
                    </Grid>
                </Box>
                <Box display={{xs: 'none', sm: 'block', md: 'block', lg: 'block'}}>
                    <Grid container className={classes.gridContainer}>
                        <Grid container item lg={6} style={{backgroundColor: '#262525'}}>
                            {infoCardGridElement}
                            {profileGridElement(true)}
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </div>
    );
}
