export interface iSection {
    id: string,
    title?: string | undefined,
    iconType?: string | undefined,
    isDiv?: boolean
}

export interface iExperienceSection {
    company: string,
    location: string,
    overview?: string,
    positions: iPosition[]
}
export interface iPosition {
    role: string,
    period: string,
    description: string
}

export const SECTIONS_DICT: Record<string, iSection> = {
    aboutme: {'iconType': 'person', 'title': 'About Me', 'id': 'aboutme'},
    education: {'iconType': 'school', 'title': 'Education', 'id': 'education'},
    experience: {'iconType': 'experience', 'title': 'Experience', 'id': 'experience'},
    techstack: {'iconType': 'skill', 'title': 'Tech Stack', 'id': 'techstack'},
    projects: {'iconType': 'project', 'title': 'Projects', 'id': 'projects'},
};
export const SECTIONS_LIST = Object.keys(SECTIONS_DICT).map(k => SECTIONS_DICT[k]);

export const CONTACT_LIST = [
    {'iconType': 'email', 'title': 'contact@carlosdurazo.dev', 'id': 'email'},
    {'iconType': 'website', 'title': 'carlosdurazo.dev', 'id': 'website'},
    {'iconType': 'phone', 'title': '+52 664 305 90 39', 'id': 'phone'},
];

export const SOCIAL_MEDIA_LIST = [
    {url: "https://github.com/memod", network: "github", label: "Github"},
    {url: "https://www.linkedin.com/in/carlos-g-durazo", network: "linkedin", label: "LinkedIn"},
    {url: "https://www.instagram.com/memo_durazo", network: "instagram", label: "Instagram"},
    {url: "https://twitter.com/cdurazo8", network: "twitter", label: "Twitter"},
];

export const EXPERIENCE_LIST = [{
        company: 'Thermo Fisher Scientific', location: 'Tijuana, MX',
        positions: [{
            role: 'Senior Software Engineer', period: 'September 2020 - Present',
            description: 'Work as team lead on a Thermo Fisher\'s e-commerce team that\'s dedicated to adding new ' +
                'functionality and migrating applications from on-premise to the cloud.'
        },{
            role: 'Software Engineer', period: 'June 2018 - September 2020',
            description: 'Work as software engineer on Thermo Fisher\'s e-commerce team, adding new functionality to the ' +
                'main page and migrating components from a monolithic architecture to using microservices.'
        }]
    }, {
        company: 'Samsung Mexicana', location: 'Tijuana, MX',
        overview: 'Worked in a R&D called SRT (Samsung Research Tijuana) that mostly focuses on Samsung Smart TVs. ' +
            'Basically covered two positions:',
        positions: [{
            role: 'Lead engineer in DevOps/DataOps team',
            period: 'March 2017 - June 2018',
            description: 'Troubleshoot and helped a development team in the US by creating tools that would ' +
                'cover needs in monitoring and alerting, as well as automatic troubleshooting. We also ' +
                'helped serve adhoc requests that came from different teams and provided the needed data in different formats.'
        }, {
            role: 'DevOps',
            period: 'December 2014 - March 2017',
            description: 'We coordinated activities and troubleshoot a system that runs 24/7. Created tools that would ' +
                'help our team diagnose and troubleshoot issues on prod.'
        }, {
            role: 'Defect Reviewer',
            period: 'August 2014 - December 2014',
            description: 'Was in charge on verifying that Smart TV apps were able to pass on to the app store ' +
                'depending on whether or not they had defects that affected the user experience or not, and if necessary helped ' +
                'developers and content providers into finding solutions for their issues.'
        }]
    }, {
        company: 'Sonata', location: 'Tijuana, MX',
        positions: [{
            role: 'Software Engineer Intern', 'period': 'February 2014 - May 2014',
            description: 'Planned and developed portal for the HR team in the company. ' +
                'This helped manage interviews with new prospects and kept control of current members.'
        }]
    }, {
        company: 'Telnor', location: 'Tijuana, MX',
        overview: '',
        positions: [{
            role: 'Software Engineer Intern',
            period: 'August 2013 - February 2014',
            description: 'My project consisted of setting up a portal that included portlets ' +
                'that users would use to share information and updates with their co-workers.'
        }]
    }, {
        company: 'InBits', location: 'Tijuana, MX',
        overview: '',
        positions: [{
            'role': 'Programmer', 'period': 'March 2013 - August 2013',
            'description': 'Worked in a project that consisted of a WMS for a client that requested a tool that ' +
                'would help manage not only their inventory, but also their providers and clients.'
        }]
    }
];

export const TECH_STACK_LIST = [{
    concept: 'Amazon Web Services',
    details: ['EC2', 'Redshift', 'PostgreSQL']
}, {
    concept: 'Programming Languages',
    details: ['Java', 'Kotlin', 'Python 2.7 & 3', 'Javascript', 'Typescript']
}, {
    concept: 'Programming Frameworks/ Engines',
    details: ['Spring Boot', 'React', 'NodeJS']
}, {
    concept: 'SQL/NoSQL Databases',
    details: ['PostgreSQL', 'Redshift', 'MySQL', 'MemSQL', 'MongoDB']
}, {
    concept: 'CI/CD',
    details: ['Git', 'Jenkins', 'Docker', 'Kubernetes']
}]
