import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    AppBar,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar
} from "@material-ui/core";
import {
    FolderShared as FolderSharedIcon,
    Menu as MenuIcon,
    Person as PersonIcon,
    School as SchoolIcon,
    Tune as TuneIcon,
    Work as WorkIcon
} from "@material-ui/icons";
import {iSection, SECTIONS_LIST} from "./constants";


const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
}));


export default function SideBarMenu() {

    const classes = useStyles();
    const anchor = "left";
    type Anchor = 'top' | 'left' | 'bottom' | 'right';
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const iconSwitch: any = (type: string) => {
        switch (type) {
            case 'person':
                return <PersonIcon/>;
            case 'school':
                return <SchoolIcon/>;
            case 'skill':
                return <TuneIcon/>;
            case 'experience':
                return <WorkIcon/>;
            case 'project':
                return <FolderSharedIcon/>;
            default:
                return;
        }
    }
    const toggleDrawer = (anchor: Anchor, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setState({...state, [anchor]: open});
    };
    const listItems = (anchor: Anchor) => (
        <div className={classes.list}
             role="presentation"
             onClick={toggleDrawer(anchor, false)}
             onKeyDown={toggleDrawer(anchor, false)}>
            <List>
                {SECTIONS_LIST.map((section: iSection) => section.isDiv ? <Divider/> : (
                    <ListItem button key={section.title} component="a" href={`#${section.id}`}>
                        <ListItemIcon>{iconSwitch(section.iconType)}</ListItemIcon>
                        <ListItemText primary={section.title}/>
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
        <>
            <AppBar position="sticky" style={{background: '#2E3B55'}}>
                <Toolbar variant="dense">
                    <IconButton edge="end"
                                color="inherit"
                                onClick={toggleDrawer(anchor, true)}
                                aria-label="menu">
                        <MenuIcon/>
                    </IconButton>
                    <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                        {listItems(anchor)}
                    </Drawer>
                </Toolbar>
            </AppBar>
        </>
    );
}