import React from "react";
import {SECTIONS_DICT} from "../constants";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {Card, CardContent, CardHeader, Grid} from "@material-ui/core";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        card: {
            backgroundColor: "#262525",
            color: "#7b7b7b",
            textAlign: "center",
            minHeight: "225px",
        },
    }));
export default function Projects() {
    const classes = useStyles();
    const moduleInfo = SECTIONS_DICT['projects'];
    return (
        <div style={{flexGrow: 5, paddingTop: '40px'}} id={moduleInfo.id}>
            <Grid container justify={"center"} alignContent={"center"} alignItems={"center"}>
                <Grid item xs={10} md={8} lg={6}>
                    <Card variant={"elevation"} className={classes.card} elevation={5}>
                        <CardHeader
                            title={'Projects'}
                        />
                        <CardContent style={{minHeight: "150px"}}>
                            <p>
                                Pacman :P
                            </p>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
