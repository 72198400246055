import React from 'react';
import './App.css';

import Layout from './components/layout';
import {CssBaseline} from "@material-ui/core";
import HeadComponent from "./components/header";
import AboutMe from "./components/sections/aboutme";
import Education from "./components/sections/education";
import Experience from "./components/sections/experience";
import TechStack from "./components/sections/techstack";
import Projects from "./components/sections/projects";
import SideBarMenu from "./components/sidebar";
import Footer from "./components/footer";

function App() {
    return (
        <Layout>
            <CssBaseline/>
            <SideBarMenu/>
            <HeadComponent/>
            <AboutMe/>
            <Education/>
            <Experience/>
            <TechStack/>
            <Projects/>
            <Footer description="I'm open to participate in open source projects! Talk to me!"/>
        </Layout>
    );
}

export default App;
