import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

function Copyright() {
    return (
        <Typography variant="body2" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://carlosdurazo.dev/">
                carlosdurazo.dev
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    footer: {
        padding: theme.spacing(6, 0),
        color: "#7b7b7b",
    },
}));

interface FooterProps {
    description: string
}

export default function Footer(props: FooterProps) {
    const classes = useStyles();
    const {description} = props;

    return (
        <footer className={classes.footer}>
            <Container maxWidth="lg">
                <Typography variant="subtitle1" align="center" component="p">
                    {description}
                </Typography>
                <Copyright/>
            </Container>
        </footer>
    );
}
