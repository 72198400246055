import React from "react";
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {iSection, SECTIONS_DICT} from "../constants";
import {Button, Card, CardActions, CardContent, Divider, Grid} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            visibility: 'visible',
            transform: 'translateY(0) scale(1)',
            opacity: 1,
            transition: 'transform 1.4s cubic-bezier(0.6, 0.2, 0.1, 1) 0.5s, opacity 1.4s cubic-bezier(0.6, 0.2, 0.1, 1) 0.5s',
            padding: theme.spacing(2),
            margin: 'auto',
        },
        image: {
            width: 128,
            height: 128,
        },
        img: {
            margin: 'auto',
            display: 'block',
            maxWidth: '100%',
            maxHeight: '100%',
        },
        card: {
            backgroundColor: "#262525",
            color: "#7b7b7b",
            textAlign: "center",
            minHeight: "225px",
        },
        buttonStyle: {
            background: "#2E3B55",
            color: "#7b7b7b",
            fontWeight: "lighter",
        },
    }),
);

export default function AboutMe() {
    const classes = useStyles();
    const moduleInfo: iSection = SECTIONS_DICT['aboutme'];
    return (
        <div id={moduleInfo.id} style={{flexGrow: 5}}>
            <Grid container justify={"center"} alignContent={"center"} alignItems={"center"}>
                <Grid item xs={10} md={8} lg={6}>
                    <Card variant={"elevation"} className={classes.card} elevation={5}>
                        <CardContent style={{minHeight: "150px"}}>
                            <p>
                                Hello! I'm Carlos Durazo, Software Engineer with +5 years of experience,
                                with hands on experience of software development, big data, DataOps and DevOps.
                                Experienced with all stages of the development cycle for dynamic web projects.
                            </p>
                        </CardContent>
                        <Divider light/>
                        <CardActions style={{justifyContent: "center"}}>
                            <Button className={classes.buttonStyle} >Download CV</Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}