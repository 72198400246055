import React from "react";
import {EXPERIENCE_LIST, iExperienceSection, SECTIONS_DICT} from "../constants";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {Card, CardContent, CardHeader, Grid} from "@material-ui/core";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        card: {
            backgroundColor: "#262525",
            color: "#7b7b7b",
            textAlign: "center",
            minHeight: "225px",
            margin: '5px',
            padding: '5px',
        },
    }));
export default function Experience() {
    const classes = useStyles();
    const moduleInfo = SECTIONS_DICT['experience'];
    const drawLines = (index: number) => {
        if (index < EXPERIENCE_LIST.length - 1)
            return <hr style={{color: '#1e1d1d'}}/>;
        return <></>
    };
    return (
        <div style={{flexGrow: 5, paddingTop: '40px'}} id={moduleInfo.id}>
            <Grid container justify={"center"} alignContent={"center"} alignItems={"center"}>
                <Grid item xs={10} md={8} lg={6}>
                    <Card variant={"elevation"} className={classes.card} elevation={5}>
                        <CardHeader
                            title={'Experience'}
                        />
                        <CardContent style={{minHeight: "150px", textAlign: "left"}}>
                            {EXPERIENCE_LIST.map((job: iExperienceSection, index: number) =>
                                <>
                                    <Card className={classes.card} elevation={0}>
                                        <CardContent>
                                            <Grid container>
                                                <Grid container item title={job.company}>
                                                    <Grid item xs={12}><h2>{job.company}</h2></Grid>
                                                    <Grid item xs={12}>{job.overview}</Grid>
                                                    {job.positions.map(position =>
                                                        <>
                                                            <Grid item xs={12}><h3><small>{position.role}</small></h3>
                                                            </Grid>
                                                            <Grid item xs={12}
                                                                  style={{fontStyle: 'italic'}}>{position.period}</Grid>
                                                            <Grid item xs={11} sm={11} md={12} lg={12}
                                                                  dangerouslySetInnerHTML={{__html: position.description}}
                                                                  style={{marginTop: '15px'}}
                                                            />
                                                        </>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    {drawLines(index)}
                                </>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}